.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#total {
  font-weight: bold;
  font-size: 18pt;
  padding-right: 20px;
}

/* Printing styles */
#receipt_print {
  width: 50%;
  margin-left:auto;
  margin-right:50px;
}
#receipt_print div {
  overflow:visible!important;
  display:block!important;
}
.reciept_item {
  page-break-inside: avoid;
  break-inside: avoid;
}
.printing .row {
  page-break-inside: avoid;
  break-inside: avoid;
  height: 120px;
}

.printing .row .text-left {
  float:left;
}

@page {
   size: auto;   /* auto is the initial value */
   margin: 10%;
}

@media print {

}
